/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { isAsyncFunction } from "@utils/appUtils";
import debounce from "debounce-promise";
import { ReactNode } from "react";
import Select, {
  ActionMeta,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  ValueContainerProps,
  components,
  createFilter,
} from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import styled, { css } from "styled-components";
import DropdownGroup from "./DropdownGroup";
import type { DropdownOption, DropdownOptionOrGroup } from "./DropdownOption";
import DROPDOWN_STYLES, { MULTI_SELECT_TAG_STYLES } from "./dropdownStyles";

interface SelectProps {
  placeholder?: string;
  placeholderColor?: string;
  label?: string;
  labelFont?: string;
  isOptional?: boolean;
  // Height of menu (default 250px)
  maxMenuHeight?: string;
  // Height of value container
  maxValueContainerHeight?: string;
  maxValues?: number;
  menuPlacement?: "bottom" | "auto" | "top";
  // Custom rendering
  withSearchIcon?: boolean;
  withChevronIcon?: boolean;
  withCheckbox?: boolean;
  components?: any;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  // For multi select (defaults true)
  isMulti?: boolean;
  // For sync search and single select
  closeOnMenuSelect?: boolean;
  options?: DropdownOptionOrGroup[];
  noOptionsMessage?: string;
  // For async search
  searchFn?: (query: string) => Promise<DropdownOptionOrGroup[]>;
  defaultOptions?: DropdownOptionOrGroup[];
  // For async and sync createable search
  isCreatable?: boolean;
  onCreateOption?: (value: string) => void;
  // For portal for dropdowns in modals or containers with hidden/scrollable overflow
  isPortal?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  helpText?: string;
  errors?: { hasError: boolean; errorMessage: string }[];
  filterOption?: (
    option: { value: string; label: string; data: any },
    inputValue: string
  ) => boolean;
  onInputChange?: (value: string, action: any) => void;
  isLoading?: boolean;
  "data-testid"?: string;
}

type MultipleSelectProps = SelectProps & {
  value: DropdownOption[];
  onChange: (value: DropdownOption[], actionMeta?: ActionMeta<DropdownOption>) => void;
};

export type SingleSelectProps = SelectProps & {
  value: DropdownOption | null;
  onChange: (value: DropdownOption | null, actionMeta?: ActionMeta<DropdownOption>) => void;
};

type Props = MultipleSelectProps | SingleSelectProps;

export const Option = ({ innerRef, innerProps, isSelected, label, isFocused }) => {
  return (
    // From React-Select docs
    // eslint-disable-next-line
    <CheckboxLabel {...innerProps} ref={innerRef} isSelected={isSelected} isFocused={isFocused}>
      <Checkbox isSelected={isSelected} isFocused={isFocused}>
        {isSelected && (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7851 3.31402C11.9568 3.47146 11.9684 3.73832 11.811 3.91007L5.62349 10.6601C5.54573 10.7449 5.43671 10.7943 5.32166 10.7968C5.20662 10.7993 5.09555 10.7547 5.01419 10.6733L2.20169 7.86081C2.03694 7.69606 2.03694 7.42894 2.20169 7.26419C2.36644 7.09944 2.63356 7.09944 2.79831 7.26419L5.29925 9.76513L11.189 3.33993C11.3465 3.16818 11.6133 3.15658 11.7851 3.31402Z"
              fill="white"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Checkbox>
      <CheckboxText>{label}</CheckboxText>
    </CheckboxLabel>
  );
};

export const NoCheckboxOption = ({ innerRef, innerProps, isSelected, isFocused, label }) => {
  return (
    <CheckboxLabel ref={innerRef} {...innerProps} isSelected={isSelected} isFocused={isFocused}>
      <CheckboxText>{label}</CheckboxText>
    </CheckboxLabel>
  );
};

const SearchIconValueContainer = ({ children, ...props }: ValueContainerProps) => (
  <StyledValueContainer>
    <Icon name="Search" color={COLORS.NEUTRAL_500} margin="0 -4px 0 10px" />
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </StyledValueContainer>
);

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="Chevron Down" />
    </components.DropdownIndicator>
  );
};

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="Close" size="xs" margin="0 0 0 8px" />
    </components.MultiValueRemove>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="X" size="xs" />
    </components.ClearIndicator>
  );
};

const MultiSelectDropdown = ({
  options,
  value,
  onChange,
  placeholder = "All",
  placeholderColor = COLORS.NEUTRAL_500,
  isOptional = false,
  noOptionsMessage = "No options",
  label,
  labelFont = FONTS.SEMIBOLD_2,
  maxMenuHeight = "250px",
  maxValueContainerHeight = "unset",
  maxValues,
  menuPlacement = "auto",
  withSearchIcon = false,
  withChevronIcon = true,
  withCheckbox = true,
  components: _components = {},
  formatCreateLabel,
  isMulti = true,
  closeOnMenuSelect = false,
  searchFn,
  defaultOptions,
  isCreatable = false,
  onCreateOption,
  isPortal = false,
  isClearable = false,
  isDisabled = false,
  helpText,
  errors,
  filterOption = createFilter({ ignoreAccents: false }),
  onInputChange,
  isLoading = false,
  "data-testid": dataTestId,
}: Props) => {
  const loadOptions = async (query: string, callback): Promise<void> => {
    if (!searchFn) {
      return;
    }
    if (isAsyncFunction(searchFn)) {
      const results = await searchFn(query);
      callback(results);
    } else {
      searchFn(query).then(callback);
    }
  };

  /**
   * Optimization: This will make the search feel faster in the instances when we use Fuse on the frontend
   * since the network call was fetched only once and the searchFn is not async.
   */
  const debouncedLoadOptions = isAsyncFunction(searchFn) ? debounce(loadOptions, 500) : loadOptions;

  const shouldHideOptions = maxValues && value instanceof Array && value.length >= maxValues;

  const hasAnyError = errors?.map((error) => error.hasError).includes(true);

  const AsyncDropdownComponent = isCreatable ? AsyncCreatableDropdown : AsyncDropdown;
  const SyncDropdownComponent = isCreatable ? SyncCreatableDropdown : SyncDropdown;

  const dropdown = searchFn ? (
    <AsyncDropdownComponent
      classNamePrefix="Select"
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      components={{
        ...{
          Option,
          DropdownIndicator,
          MultiValueRemove,
          ClearIndicator,
          ValueContainer: withSearchIcon ? SearchIconValueContainer : components.ValueContainer,
        },
        ..._components,
      }}
      formatGroupLabel={DropdownGroup}
      formatCreateLabel={formatCreateLabel}
      defaultOptions={defaultOptions}
      cacheOptions
      loadOptions={debouncedLoadOptions}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      hideSelectedOptions={false}
      closeMenuOnSelect={closeOnMenuSelect}
      maxMenuHeight={maxMenuHeight}
      maxValueContainerHeight={maxValueContainerHeight}
      menuIsOpen={shouldHideOptions ? false : undefined}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? noOptionsMessage : "Start typing to search"
      }
      creatable={isCreatable}
      menuPlacement={menuPlacement}
      menuPosition={isPortal && "absolute"}
      menuPortalTarget={isPortal && document.body}
      menuShouldBlockScroll={isPortal}
      isClearable={isClearable}
      isDisabled={isDisabled}
      styles={{
        menuPortal: (base) => ({
          ...base,
          // Portalled dropdown menu list should display over everything
          zIndex: 9999,
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: maxMenuHeight,
        }),
      }}
      hasError={hasAnyError}
    />
  ) : (
    <SyncDropdownComponent
      classNamePrefix="Select"
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      components={{
        ...{
          Option: withCheckbox ? Option : NoCheckboxOption,
          DropdownIndicator: withChevronIcon ? DropdownIndicator : () => null,
          MultiValueRemove,
          ClearIndicator,
          ValueContainer: withSearchIcon ? SearchIconValueContainer : components.ValueContainer,
        },
        ..._components,
      }}
      formatGroupLabel={DropdownGroup}
      formatCreateLabel={formatCreateLabel}
      options={options}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      isLoading={isLoading}
      filterOption={filterOption}
      isMulti={isMulti}
      hideSelectedOptions={false}
      noOptionsMessage={() => noOptionsMessage}
      closeMenuOnSelect={closeOnMenuSelect}
      maxMenuHeight={maxMenuHeight}
      maxValueContainerHeight={maxValueContainerHeight}
      menuIsOpen={shouldHideOptions ? false : undefined}
      menuPlacement={menuPlacement}
      menuPosition={isPortal && "absolute"}
      menuPortalTarget={isPortal && document.body}
      menuShouldBlockScroll={isPortal}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onCreateOption={onCreateOption}
      styles={{
        menuPortal: (base) => ({
          ...base,
          // Portalled dropdown menu list should display over everything
          zIndex: 9999,
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: maxMenuHeight,
        }),
      }}
      hasError={hasAnyError}
    />
  );

  return (
    <Container>
      {label ? (
        <DropdownLabel labelFont={labelFont}>
          <LabelText hasError={hasAnyError}>
            <div>{label}</div>
            {isOptional && <Optional>Optional</Optional>}
          </LabelText>
          <span data-testid={dataTestId}>{dropdown}</span>
        </DropdownLabel>
      ) : (
        <span data-testid={dataTestId}>{dropdown}</span>
      )}
      {Number(helpText?.length) > 0 && <HelpText>{helpText}</HelpText>}
      {hasAnyError &&
        errors?.map(
          (error) =>
            error.hasError && (
              <ErrorMessage key={error.errorMessage}>
                <Icon name="Attention" size="sm" margin="0 8px 0 0" color={COLORS.RED_600} />
                {error.errorMessage}
              </ErrorMessage>
            )
        )}
    </Container>
  );
};

export default MultiSelectDropdown;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  ${({ isSelected }) => (isSelected ? FONTS.MEDIUM_2 : FONTS.REGULAR_2)};
  color: ${({ isSelected }) => (isSelected ? COLORS.HALO_BLUE : COLORS.BLACK)};
  margin: 0;
  padding: 10px 14px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ isFocused }) => (isFocused ? COLORS.NEUTRAL_100 : "inherit")};
`;
// Also used in KeywordsDropdownOption
export const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 3px;
  border: 1px solid ${({ isSelected }) => (isSelected ? COLORS.HALO_BLUE : COLORS.BLACK)};
  margin-right: 10px;
  background-color: ${({ isSelected }) => (isSelected ? COLORS.HALO_BLUE : "transparent")};
`;
export const CheckboxText = styled.span`
  overflow-wrap: break-word;
  white-space: normal;
`;
// Also used in SelectOrCreateDropdown
export const MultiSelectDropdownBaseStyles = css`
  .Select__control {
    flex-wrap: nowrap;
    border-radius: 6px;
    margin-top: 8px;
    ${({ hasError }) =>
      hasError &&
      `
        border-color: ${COLORS.RED_600};
        background-color: ${COLORS.RED_100};
        &:hover {
          &:not(.Select__control--is-focused) {
            border-color: ${COLORS.RED_600};
          }
          &.Select__control--is-focused {
            border-color: ${COLORS.RED_600};
          }
        }
    `}
    ${FONTS.REGULAR_2};
  }
  .Select__clear-indicator {
    ${({ isClearable }) => !isClearable && "display: none;"}
  }
  .Select__menu {
    ${FONTS.REGULAR_2};
    z-index: 9999;
  }
  .Select__menu-list {
    max-height: ${({ maxMenuHeight }) => maxMenuHeight};
  }
  ${({ maxValueContainerHeight }) => `
    .Select__value-container {
      max-height: ${maxValueContainerHeight};
      overflow-y: auto;
    }
  `}
  .Select__placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`;
const AsyncDropdown = styled(AsyncSelect)`
  ${DROPDOWN_STYLES};
  ${MULTI_SELECT_TAG_STYLES};
  ${MultiSelectDropdownBaseStyles};

  .Select__dropdown-indicator {
    display: none;
  }
  .Select__indicators {
    &:hover {
      opacity: 0.5;
    }
  }
`;
const AsyncCreatableDropdown = styled(AsyncCreatableSelect)`
  ${DROPDOWN_STYLES};
  ${MULTI_SELECT_TAG_STYLES};
  ${MultiSelectDropdownBaseStyles};

  .Select__single-value {
    ${FONTS.REGULAR_2};
  }
  .Select__indicators {
    &:hover {
      opacity: 0.5;
    }
  }
`;
const SyncDropdown = styled(Select)`
  ${DROPDOWN_STYLES};
  ${MULTI_SELECT_TAG_STYLES};
  ${MultiSelectDropdownBaseStyles};
`;
const SyncCreatableDropdown = styled(CreatableSelect)`
  ${DROPDOWN_STYLES};
  ${MULTI_SELECT_TAG_STYLES};
  ${MultiSelectDropdownBaseStyles};
`;
const DropdownLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  ${({ labelFont }) => labelFont && labelFont};
`;
export const LabelText = styled.div`
  display: flex;
  color: ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.BLACK)};
`;
export const Optional = styled.span`
  position: relative;
  ${FONTS.MEDIUM_2};
  height: 21px;
  color: ${COLORS.NEUTRAL_500};
  margin-left: 16px;
`;
const HelpText = styled.div`
  margin-top: 8px;
  ${FONTS.REGULAR_2};
`;
const ErrorMessage = styled.div`
  display: flex;
  ${FONTS.MEDIUM_3};
  color: ${COLORS.RED_600};
`;
const StyledValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`;

// Note: Not all pages should be wrapped in this component.  This component assumes that there is
// even padding along the sides of the entire page, but some pages (e.g. inbox, proposal review
// system, pages with banners, etc.) are meant to have some or all of the contents extend across
// the entire width of the browser window.

import { COLORS, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  hasTopDivider?: boolean;
  hasPaddingTop?: boolean;
  hasPaddingBottom?: boolean;
  maxWidth?: string; // Should not be used; used only for a hacky approach for RFS requested by Kevin
  children: ReactNode;
}

interface ContainerProps {
  hasTopDivider: boolean;
  hasPaddingTop: boolean;
  hasPaddingBottom: boolean;
}

const PageContainer = ({
  hasTopDivider = false,
  hasPaddingTop = true,
  hasPaddingBottom = true,
  maxWidth,
  children,
}: Props) => {
  return (
    <Container
      hasTopDivider={hasTopDivider}
      hasPaddingTop={hasPaddingTop}
      hasPaddingBottom={hasPaddingBottom}
    >
      <Children maxWidth={maxWidth}>{children}</Children>
    </Container>
  );
};

export default PageContainer;

const {
  TABLET_MEDIA_QUERY,
  DESKTOP_MIN_WIDTH_MEDIA_QUERY,
  DESKTOP_MIN_CONTENT_WIDTH,
  DESKTOP_MIN_CONTENT_WIDTH_MEDIA_QUERY,
  DEFAULT_MAX_WIDTH,
  PAGE_SIDE_PADDING,
  TABLET_SIDE_PADDING,
} = WINDOW_DIMENSIONS;
const Container = styled.div<ContainerProps>`
  ${({ hasPaddingTop }) => hasPaddingTop && "padding-top: 69px"};
  ${({ hasPaddingBottom }) => hasPaddingBottom && "padding-bottom: 69px"};

  ${({ hasTopDivider }: ContainerProps) =>
    hasTopDivider && `border-top: 1px solid ${COLORS.NEUTRAL_100};`}

  padding-right: ${PAGE_SIDE_PADDING}px;
  padding-left: ${PAGE_SIDE_PADDING}px;
  ${DESKTOP_MIN_WIDTH_MEDIA_QUERY} {
    padding-right: unset;
    padding-left: unset;
  }
  ${DESKTOP_MIN_CONTENT_WIDTH_MEDIA_QUERY} {
    padding-right: ${PAGE_SIDE_PADDING}px;
    padding-left: ${PAGE_SIDE_PADDING}px;
  }

  ${TABLET_MEDIA_QUERY} {
    padding: ${TABLET_SIDE_PADDING}px;
  }
`;
const Children = styled.div`
  max-width: ${({ maxWidth }) => maxWidth ?? `${DEFAULT_MAX_WIDTH}px`};
  margin: 0 auto;

  ${DESKTOP_MIN_WIDTH_MEDIA_QUERY} {
    width: ${DESKTOP_MIN_CONTENT_WIDTH}px;
  }
  ${DESKTOP_MIN_CONTENT_WIDTH_MEDIA_QUERY} {
    width: unset;
  }
`;

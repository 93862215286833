// Some things to note:
// 1. Order users[] before passing them as props
// 2. displayedUserCount is a count for how many users to be shown in thumbnail
// 3. set displayedUserCount to 1 if you are working with a size 'md' thumbnail (based on designs)

import { COLORS } from "@constants";
import styled, { css } from "styled-components";
import ProfilePicture from "../ProfilePicture/ProfilePicture";

interface Props {
  users: {
    id: number;
    image: { medium: { url: string | null } };
    name: string;
  }[];
  size: "xs" | "sm" | "md" | "lg";
  displayedUserCount?: 1 | 2 | 3 | 4; // default -> 2
  single?: boolean; // default -> false
}

const getMargin = (index: number, size: string) => {
  // note: only size medium should have different margins
  if (index === 0 && size !== "md") {
    return index;
  } else if (size === "md") {
    return "14px 0 0 0";
  }
  return "0 0 0 -9px";
};

const Thumbnail = ({ users, size, displayedUserCount = 2, single = false }: Props) => {
  if (single) {
    return <ProfilePicture size={size} user={users[0]} />;
  }
  const displayedUsers = users.slice(0, size === "md" ? 1 : displayedUserCount);
  const shouldShowAdditionalUsers = (size === "md" ? 1 : displayedUserCount) < users.length;

  return (
    <MulitReviewersContainter size={size}>
      {displayedUsers.map((user, index) => {
        return (
          <div data-testid="reviewer-chip" key={user.id}>
            <ProfilePicture
              // medium size profile picture is actually a small when there are multiple reviewers (according to the designs)
              size={size === "md" ? "sm" : size}
              forMulti
              margin={getMargin(index, size)}
              user={user}
            />
          </div>
        );
      })}
      {shouldShowAdditionalUsers && (
        <AdditionalParticipants size={size}>
          +{users.length - (size === "md" ? 1 : displayedUserCount)}
        </AdditionalParticipants>
      )}
    </MulitReviewersContainter>
  );
};

export default Thumbnail;

const MulitReviewersContainter = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  float: left;
  align-items: ${(props) => (props.size === "md" ? "unset" : "center")};
  height: ${(props) => (props.size === "md" ? "50px" : "auto")};
  width: ${(props) => (props.size === "md" ? "50px" : "auto")};
`;

const AdditionalParticipants = styled.div`
  font-weight: 500;
  border: 1px solid ${COLORS.WHITE};
  background: ${COLORS.NEUTRAL_200};
  border-radius: 50%;
  color: ${COLORS.BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -9px;
  ${({ size }) => {
    switch (size) {
      case "xs": {
        return css`
          font-size: 12px;
          height: 28px;
          width: 28px;
        `;
      }
      case "sm": {
        return css`
          font-size: 16px;
          height: 36px;
          width: 36px;
        `;
      }
      case "md": {
        return css`
          font-size: 16px;
          height: 36px;
          width: 36px;
          margin-left: -21px;
        `;
      }
      case "lg": {
        return css`
          font-size: 23px;
          font-weight: 600;
          height: 50px;
          width: 50px;
        `;
      }
    }
  }};
`;

import Icon from "@components/library/Icon";
import { COLORS, FONTS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import { ReactElement, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import IconButton from "../Buttons/IconButton";
import { IconName } from "../Icon/Icon";

export interface BlankModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeOnDimmerClick?: boolean;
  headerText?: string | ReactElement;
  headerIconName?: IconName;
  subHeaderText?: string;
  subHeaderIconName?: IconName;
  hideCloseButton?: boolean;
  disableBackgroundScroll?: boolean;
  width?: string;
  height?: string;
  backgroundMode?: "dim" | "blur";
  children?: ReactNode;
  zIndexBase?: number;
}

const BlankModal = ({
  isOpen,
  onClose,
  closeOnDimmerClick = true,
  headerText,
  headerIconName,
  subHeaderText,
  subHeaderIconName,
  hideCloseButton = false,
  disableBackgroundScroll = true,
  width,
  height,
  backgroundMode = "dim",
  children,
  zIndexBase = 500,
}: BlankModalProps) => {
  const handleDimmerClick = () => {
    if (closeOnDimmerClick) {
      onClose();
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code === "Escape" && isOpen) {
      if (closeOnDimmerClick) {
        onClose();
      }
    }
  };

  useEffect(() => {
    // Close on Escape
    document.addEventListener("keydown", onKeyDown, false);

    // Disable background scroll of anchor
    // Targets the top modal (not including the current one) if it exists
    // Targets html element otherwise
    const modals = document.querySelectorAll("[role=dialog]");
    const anchor = (modals[modals.length - (isOpen ? 2 : 1)] ||
      document.querySelector("html")) as HTMLElement;
    if (disableBackgroundScroll && isOpen) anchor.style.overflow = "hidden";

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);

      if (disableBackgroundScroll) anchor.style.overflow = "unset";
    };
  }, [isOpen]);

  const modal = (
    <>
      <Background onClick={handleDimmerClick} mode={backgroundMode} zIndexBase={zIndexBase} />
      <Wrapper
        aria-modal
        aria-labelledby={headerText ?? "Modal Title"}
        tabIndex={-1}
        role="dialog"
        zIndexBase={zIndexBase}
      >
        <StyledModal width={width} height={height} zIndexBase={zIndexBase}>
          {!hideCloseButton && (
            <CloseButton data-testid="modal-close-button">
              <IconButton iconName="Close" size="sm" variant="fourth" onClick={onClose} />
            </CloseButton>
          )}
          {headerText && (
            <>
              <Header>
                {headerIconName && <Icon name={headerIconName} size="xxl" margin="0 16px 0 0" />}
                <HeaderContent>
                  <HeaderText>{headerText}</HeaderText>
                  {subHeaderText && (
                    <SubHeader>
                      {subHeaderIconName && (
                        <Icon name={subHeaderIconName} size="xs" color={COLORS.BLACK} />
                      )}
                      <div>{subHeaderText}</div>
                    </SubHeader>
                  )}
                </HeaderContent>
              </Header>
              <Divider />
            </>
          )}
          <Content hasHeader={headerText}>{children}</Content>
        </StyledModal>
      </Wrapper>
    </>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
};

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${({ mode }) =>
    (mode === "blur" && "backdrop-filter: blur(6px);") ||
    (mode === "dim" &&
      `
      background-color: ${COLORS.BLACK};
      opacity: 0.7;
  `)}
  z-index: ${({ zIndexBase }) => zIndexBase + 1};
`;

const Wrapper = styled.div`
  z-index: ${({ zIndexBase }) => zIndexBase + 2};
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    align-items: flex-start;
  }

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    width: 90%;
  }

  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    width: 100%;
  }
`;

const StyledModal = styled.div`
  z-index: ${({ zIndexBase }) => zIndexBase + 3};
  background: white;
  position: relative;
  border-radius: 12px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  box-shadow: ${STYLES.SHADOW_D};

  max-height: 90vh;
  overflow: visible;

  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    width: 90%;
  }
`;

const Header = styled.div`
  border-radius: 8px 8px 0 0;
  padding: 24px 20px;
  display: flex;
  align-items: center;
`;

const HeaderContent = styled.div`
  width: 100%;
`;

const SubHeader = styled.div`
  display: flex;
  padding-top: 4px;
  max-width: 90%;
  ${FONTS.REGULAR_3};
  align-items: center;
  gap: 8px;
  > div:last-child {
    ${STYLES.ONE_LINE_ALT};
  }
  flex-direction: row;
`;

const HeaderText = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD}
  color: ${COLORS.BLACK};
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.hr`
  margin: 0;
  border-color: ${COLORS.NEUTRAL_300};
`;

const Content = styled.div`
  max-height: ${({ hasHeader }) => (hasHeader ? "calc(100% - 76px)" : "100%")};
  overflow-y: auto;
  overflow-x: hidden;
`;

const CloseButton = styled.div`
  width: fit-content;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export default BlankModal;

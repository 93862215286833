// NOTE: Also update config/initializers/request_custom_data_data_type.rb
export interface RequestCustomData {
  is_from_pepsico_procurement: boolean;
}

export enum RequestableType {
  OPEN_CALL = "OpenCall",
  RFP = "Rfp",
  REQUEST_FOR_STARTUP = "RequestForStartup",
  REQUEST_FOR_EXPERT = "RequestForExpert",
}

export enum LaunchableRequestStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}

export enum RequestFeedbackDeadlineStatus {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NEEDS_DEADLINE = "NEEDS_DEADLINE",
  BEFORE_DEADLINE = "BEFORE_DEADLINE",
  DEADLINE_APPROACHING = "DEADLINE_APPROACHING",
  PAST_DEADLINE = "PAST_DEADLINE",
}

export enum RequestPartnerTypes {
  ACADEMIC_RESEARCHERS = "ACADEMIC_RESEARCHERS",
  STARTUPS = "STARTUPS",
  SUPPLIERS = "SUPPLIERS",
}

import { company_marketplace_filter_options_path, company_marketplace_path } from "@routes/routes";
import { marketplacePath } from "@routes/utils";
import { CompanyForCard } from "@tsTypes/companies/_for_card";
import { CompanyMarketplaceResponse } from "@tsTypes/companies/company_marketplace";
import { CompanyMarketplaceFilterOptionsResponse } from "@tsTypes/companies/company_marketplace_filter_options";
import {
  CompaniesShowResponse,
  CompanyPageResponse,
  CompanyResearchInterestsCreateResponse,
  CompanyResearchInterestsDestroyResponse,
  CompanyResearchInterestsUpdateResponse,
  RfpRequestForCard,
} from "@tsTypes/index";
import { Company, CompanyResearchInterest, Request, User } from "@tsTypes/schema";
import api from "./request";

export type CompanyAsJson = Omit<
  Company,
  "request_for_proposals" | "company_page_users" | "company_research_interests"
> & {
  last_subscription_end_date?: string;
  rfp_requests: RfpRequestForCard[];
  company_research_interests: CompanyResearchInterest &
    {
      rfp_requests: RfpRequestForCard[];
      keywords: string[];
      open_call_request: Request;
    }[];
  super_admin: User;
  company_page_users?: User[];
  opportunity_count: number;
  is_open_calls_accessible: boolean;
};

export const getCompanyForCompanyPage = async (
  identifier: string
): Promise<CompanyPageResponse> => {
  try {
    const response = await api.get(`/company_info/${identifier.toLocaleLowerCase()}`, {
      params: {
        is_for_company_page: true,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      const accessCode = prompt("Please enter the access code for this page");

      if (accessCode) {
        try {
          const responseJson = await api.get(`/company_info/${identifier}`, {
            params: {
              access_code: accessCode,
              is_for_company_page: true,
            },
          });

          return responseJson.data;
        } catch (secondError) {
          if (secondError.response.status === 401) {
            alert("Incorrect password");
            window.location.href = marketplacePath();
          } else {
            throw secondError;
          }
        }
      }

      window.location.href = marketplacePath();
    } else {
      throw error;
    }
  }

  throw new Error("Unable to fetch company");
};

export async function getCompany(identifier: string): Promise<CompaniesShowResponse> {
  if (!identifier) {
    console.error(`No identifier passed to getCompany!`);
  }
  const response = await api.get(`/company_info/${identifier}`);

  return response.data;
}

export async function getCompanyBranding(companyId: number) {
  try {
    const response = await api.get("/company_brandings", { params: { company_id: companyId } });
    const { company_branding } = response.data;
    return company_branding;
  } catch (error) {
    return null;
  }
}

export async function getAllCompanies(
  pageNumber?: number,
  sortBy?: string,
  abortSignal?: AbortSignal
): Promise<{ companies: CompanyForCard[]; total_count: number }> {
  const request = await api.get("/companies", {
    params: {
      for_marketplace: true,
      page_number: pageNumber,
      sort_by: sortBy,
    },
    signal: abortSignal,
  });
  return request.data;
}

export async function getCompanyFollowSuggestions() {
  try {
    const request = await api.get("/companies", { params: { for_suggested_follows: true } });
    const { companies } = request.data;
    return companies;
  } catch {
    return [];
  }
}

export async function getCompanyNamesForOnboarding(): Promise<{ companies: string[] }> {
  const response = await api.get("/companies", { params: { for_onboarding: true } });
  return response.data;
}

export async function uploadCompanyVideo(id: number, videoBlob: Blob) {
  const formData = new FormData();
  formData.append("file", videoBlob);

  const response = await api.post(`/company/${id}/video-upload`, formData);
  const videoUrl: string = response.data.url;

  return videoUrl;
}

export async function uploadCompanyImage(id: number, identifier: string, imageBlob: Blob) {
  const formData = new FormData();
  formData.append("file", imageBlob, `${identifier}-logo.png`);

  const response = await api.post(`/company/${id}/logo-upload`, formData);
  const logoUrl: string = response.data.url;

  return logoUrl;
}

export async function followUnfollowCompany(identifier?: string | null) {
  if (!identifier) return;
  try {
    const response = await api.post(`/company/${identifier}/follow_unfollow`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function updateCompanyInfo(
  id: number,
  companyInfo: {
    researcher_count?: string;
    rd_centers_count?: number;
    rd_spend_amount?: string;
    employee_count?: string;
    company_type?: string;
    location?: string;
    description?: string;
    introduction?: string;
    company_page_user_ids?: number[];
  }
): Promise<CompanyPageResponse | null> {
  try {
    const response = await api.put(`/companies/${id}`, companyInfo);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function createCompanyResearchInterest({
  companyId,
  title,
  summary,
  keywords,
  requestForProposalIds,
  isOpenCallActive,
}: {
  companyId: number;
  title: string;
  summary: string;
  keywords?: string[];
  requestForProposalIds?: number[];
  isOpenCallActive: boolean;
}): Promise<CompanyResearchInterestsCreateResponse> {
  const response = await api.post(`/company_research_interests`, {
    company_id: companyId,
    title,
    summary,
    keywords,
    request_for_proposal_ids: requestForProposalIds,
    is_open_call_active: isOpenCallActive,
  });

  return response.data;
}

export async function updateCompanyResearchInterest({
  id,
  title,
  summary,
  keywords,
  requestForProposalIds,
  isOpenCallActive,
}: {
  id: number;
  title: string;
  summary: string;
  keywords: string[];
  requestForProposalIds: number[];
  isOpenCallActive: boolean;
}): Promise<CompanyResearchInterestsUpdateResponse> {
  const response = await api.put(`/company_research_interests/${id}`, {
    title,
    summary,
    keywords,
    request_for_proposal_ids: requestForProposalIds,
    is_open_call_active: isOpenCallActive,
  });

  return response.data;
}

export async function deleteCompanyResearchInterest(
  id: number
): Promise<CompanyResearchInterestsDestroyResponse> {
  const response = await api.delete(`/company_research_interests/${id}`);

  return response.data;
}

export type CompanyResearchInterestForProposalForm = CompanyResearchInterest & {
  request: Pick<Request, "id" | "title" | "slug">;
  company: Pick<Company, "company_name" | "avatar" | "is_partner" | "identifier">;
};

export const getCompanyResearchInterestsBySlug = async (
  slug: string
): Promise<CompanyResearchInterestForProposalForm[]> => {
  const response = await api.get(`/company_research_interests`, {
    params: { company_identifier: slug },
  });
  return response.data.company_research_interests;
};

export const getCompanyMarketplaceCompanies = async ({
  keywordsFilter,
  withActiveRfpsFilter,
  withActiveOpenCallsFilter,
  withActiveRfsFilter,
  sortBy,
  pageNumber,
}: {
  keywordsFilter: string[];
  withActiveRfpsFilter: boolean;
  withActiveOpenCallsFilter: boolean;
  withActiveRfsFilter: boolean;
  sortBy: "PREFERRED" | "NAME";
  pageNumber: number;
}): Promise<CompanyMarketplaceResponse> => {
  const response = await api.get(company_marketplace_path(), {
    params: {
      options: {
        keywords_filter: keywordsFilter,
        with_active_rfps_filter: withActiveRfpsFilter,
        with_active_open_calls_filter: withActiveOpenCallsFilter,
        with_active_rfs_filter: withActiveRfsFilter,
        sort_by: sortBy,
        page_number: pageNumber,
      },
    },
  });
  return response.data;
};

export const getCompanyMarketplaceFilterOptions =
  async (): Promise<CompanyMarketplaceFilterOptionsResponse> => {
    const response = await api.get(company_marketplace_filter_options_path());
    return response.data;
  };

import { Icon, Tooltip } from "@components/library";
import { COLORS } from "@constants";
import { t } from "@utils/i18n";

interface Props {
  size?: "xs" | "sm";
}

export function SuperAdminTag({ size = "xs" }: Props) {
  return (
    <div>
      <Tooltip content={<>{t("components.super_admin_tag.text")} </>} tooltipWidth="290px">
        <Icon name="Crown" color={COLORS.ORANGE_500} size={size} />
      </Tooltip>
    </div>
  );
}
